import { navigate, Link } from "gatsby";
import React, { useState } from "react";

import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import OnboardingNavbar from "../../../components/onboarding_navbar";
import ProfilePictureComponent from "../../../components/profile_picture_upload";

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

const ProfilePicturePage = () => {
  const onUploadSuccess = () => {
    navigate("/facilitator/signup/name_circle/");
  };

  return (
    <div className="page">
      <OnboardingNavbar link="/facilitator/login" />
      <ProfilePictureComponent
        onUploadSuccess={onUploadSuccess}
        pageTitle={"Facilitator Profile Picture"}
      />
    </div>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <ProfilePicturePage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
